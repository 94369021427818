<script setup lang="ts">
import type { VoixCheckboxFieldInterface, VoixMediaFieldInterface, VoixTextFieldInterface } from '@voix/types'
import { animate, scroll, ScrollOffset } from 'motion'

const props = defineProps<{
  fields: {
    onLeft: VoixCheckboxFieldInterface
    media: VoixMediaFieldInterface
    title: VoixTextFieldInterface
    customer: VoixTextFieldInterface
    tags: VoixTextFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Project Normal', group: 'Projects', layouts: ['LogicbombMedia'] },
  description: '',
  preview: 'SlicesProjectsNormal.jpg',
  fields: {
    onLeft: {
      type: 'checkbox',
      label: 'On Left',
      default: true,
    },
    media: {
      type: 'media',
      label: 'Media',
      breakpoints: {
        default: {
          width: 505,
          height: 283,
        },
      },
    },
    title: {
      type: 'text',
      label: 'Title',
    },
    customer: {
      type: 'text',
      label: 'Customer',
    },
    tags: {
      type: 'text',
      label: 'Tags',
      instructions: 'Comma separated list of tags',
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const tagsArray = props.fields.tags.value ? props.fields.tags.value.split(',').map((tag: string) => tag.trim()) : []

const el = ref<HTMLElement | null>(null)
const contents = ref<HTMLElement | null>(null)
const media = ref<HTMLElement | null>(null)

onMounted(() => {
  const scrollInOptions = {
    target: el.value,
    offset: ['start end', 'end start'],
  }

  const scrollOutOptions = {
    target: el.value,
    offset: ScrollOffset.Exit,
  }

  scroll(
    animate(contents.value, { transform: ['translateY(100px)', 'translateY(-100px)'] }),
    scrollInOptions,
  )

  scroll(
    animate(contents.value, { opacity: [1, 0] }),
    scrollOutOptions,
  )
})
</script>

<template>
  <div ref="el" class="group flex hover:-translate-y-4 duration-500 ease-out px-12 lg:px-0" :class="{ 'justify-end': !fields.onLeft.value }">
    <div class="w-full h-full">
      <div ref="contents" class="border border-stone-800  h-full">
        <div class="aspect-video overflow-hidden">
          <div ref="media" class="object-cover w-full h-full">
            <VoixMedia :field="fields.media" class="object-cover w-full h-full group-hover:scale-125 duration-500" />
          </div>
          <VoixMedia :field="fields.media" class="object-cover w-full h-full group-hover:scale-125 duration-500 blur-xl" />
        </div>
        <div class="bg-stone-950/75 backdrop-blur">
          <h2 class="py-5 px-6 flex flex-col text-3xl font-lbm-serif text-stone-200 tracking-tight">
            <span class="font-lbm-sans text-xs uppercase font-light tracking-widest">{{ fields.customer.value }}</span>
            <span>{{ fields.title.value }}</span>
          </h2>
          <ul class="flex flex-wrap gap-2 border-t border-stone-800 py-5 px-6 ">
            <li v-for="tag in tagsArray" :key="tag" class="text-xs tracking-wider font-lbm-sans text-stone-400 border border-stone-800 uppercase  px-2 py-1">
              {{ tag }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
